<template>
  <BModal
    id="modal-cancel"
    ref="modal-cancel"
    hide-footer
    hide-header
    modal-class="modal-dark"
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <BCol
      md="12"
      class="d-flex justify-content-center pt-3"
    >
      <b-img
        width="100"
        src="https://storage.googleapis.com/komerce/assets/icons/popup-warning.png"
      />
    </BCol>

    <BCol class="text-center mt-2">
      <h5 class="text-black">
        Apakah kamu yakin ingin membatalkan edit fulfillment?
      </h5>
    </BCol>
    <div class="text-center my-2">
      <BButton
        variant="outline-primary"
        class="mr-1"
        @click="$bvModal.hide('modal-cancel')"
      >
        Tidak
      </BButton>
      <BButton
        variant="primary"
        @click="cancel"
      >
        Batalkan
      </BButton>
    </div>
  </BModal>
</template>

<script>
import { BCol, BButton, BModal } from 'bootstrap-vue'

export default {
  components: { BCol, BButton, BModal },
  methods: {
    cancel() {
      this.$refs['modal-cancel'].hide()
      window.location.reload()
    },
  },
}
</script>
