<template>
  <BCard>
    <BRow class="items-center">
      <BCol
        cols-lg="6"
        cols-sm="6"
      >
        <div class="m-0 font-medium text-black text-2xl">
          Detail Fullfilment Service
        </div>
      </BCol>
      <BCol
        cols-lg="6"
        cols-sm="6"
        class="text-end"
      >
        <BButton variant="primary">
          Export
        </BButton>
      </BCol>
    </BRow>
    <BCard
      class="rounded-md p-0 mt-1"
      style="border: 1px solid #e2e2e2"
    >
      <BRow class="items-center">
        <BCol
          cols-lg="6"
          cols-sm="6"
        >
          <div class="text-lg font-medium m-0 text-black">
            Partner
          </div>
        </BCol>
        <BCol
          cols-lg="6"
          cols-sm="6"
          class="text-end"
        >
          <BButton
            variant="outline-primary"
            @click="handlePhone(dataFullfilmentProduct.phone_number)"
          >
            Chat
          </BButton>
        </BCol>
      </BRow>
      <BRow class="my-1">
        <BCol lg="4">
          <div class="flex">
            <BAvatar
              size="lg"
              :src="dataFullfilmentProduct.image_logo_url"
            />
            <div class="ml-1">
              <p class="m-0 font-medium text-black">
                {{ dataFullfilmentProduct.name }}
              </p>
              <p class="m-0">
                {{ dataFullfilmentProduct.city }}
              </p>
            </div>
          </div>
        </BCol>
      </BRow>
      <BRow>
        <BCol
          lg="2"
          class="text-black"
        >
          Owner
        </BCol>
        <BCol
          lg="4"
          class="text-black"
        >
          {{ dataFullfilmentProduct.owner }}
        </BCol>
        <BCol
          lg="2"
          class="text-black"
        >
          Status
        </BCol>
        <BCol
          lg="4"
          class="text-black"
        >
          {{ dataFullfilmentProduct.submission_status }} sejak {{ DAY_MONTH_YEAR(dataFullfilmentProduct.submission_date) }}
        </BCol>
      </BRow>
      <BRow class="my-1">
        <BCol
          lg="2"
          class="text-black"
        >
          Sektor Bisnis
        </BCol>
        <BCol
          lg="4"
          class="text-black"
        >
          {{ dataFullfilmentProduct.business_sector }}
        </BCol>
        <BCol
          lg="2"
          class="text-black"
        >
          Produk
        </BCol>
        <BCol
          lg="4"
          class="text-black"
        >
          {{ dataFullfilmentProduct.product_total }}
        </BCol>
      </BRow>
      <BRow>
        <BCol
          lg="2"
          class="text-black"
        >
          Tipe Bisnis
        </BCol>
        <BCol
          lg="4"
          class="text-black"
        >
          {{ dataFullfilmentProduct.business_type }}
        </BCol>
        <BCol
          lg="2"
          class="text-black"
        >
          Email
        </BCol>
        <BCol
          lg="4"
          class="text-black"
        >
          {{ dataFullfilmentProduct.email }}
        </BCol>
      </BRow>
    </BCard>
    <div class="flex justify-between items-center">
      <div class="font-medium m-0 text-black text-lg">
        Data Produk
      </div>
      <BButton
        variant="outline-primary"
        :disabled="disabled ? true : false"
        @click="onClickEdit"
      >
        Edit
      </BButton>
    </div>

    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        ref="selectableTable"
        :items="items"
        :fields="fields"
        class="mt-1"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        show-empty
      >
        <template #head(income)="data">
          <div class="flex items-center">
            <span>{{ data.label }}</span>
            <img
              v-b-tooltip.hover.top="'Biaya fullfilement untuk penambahan quantity dari suatu barang dalam 1 resi'"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              alt="Komerce"
              class="ml-[5px]"
            >
          </div>
        </template>
        <template #head(packing_layer)="data">
          <div class="flex items-center">
            <span>{{ data.label }}</span>
            <img
              v-b-tooltip.hover.top="'PL : Plastik\nBW : Bubble Wrap\nKD : Kardus'"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              alt="Komerce"
              class="ml-[5px]"
            >
          </div>
        </template>
        <template #cell(product_name)="data">
          <div
            class="flex items-start"
          >
            <img
              :src="data.item.image_path === null ? 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg' : data.item.image_path"
              alt="Komerce"
              width="52"
              height="52"
            >
            <div class="ml-[5px]">
              <div class="font-medium">
                {{ data.item.product_name }}
              </div>
              <div class="text-[12px] text-primary">
                SKU: {{ data.item.sku }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(fulfillment_cost)="data">
          <span v-if="!disabled">
            {{ IDR(data.item.fulfillment_cost) }}
          </span>
          <BFormInput
            v-else
            v-model="data.item.fulfillment_cost"
            type="number"
            style="width: 100px"
            @keypress="isNumber"
          />
        </template>
        <template #cell(packing_material)="data">
          <div class="!min-w-[170px]">
            <div v-if="disabled">
              <BDropdown
                :text="handlePackingText(data.item.packing_material)"
                variant="outline-dark"
              >
                <div
                  v-for="(item, index) in data.item.packing_material"
                  :key="index"
                  class="p-50"
                >
                  <BFormCheckbox
                    v-model="item.isActive"
                    @input="item.isActive === true ? data.item.packing_material[index].layer = 1 : data.item.packing_material[index].layer = 0"
                  >
                    <span class="text-black">{{ item.text }}</span>
                  </BFormCheckbox>
                </div>
              </BDropdown>
            </div>
            <div v-else>
              {{ data.item.packing_material.filter(e => e.isActive).map(e => e.text).join(', ') }}
            </div>
          </div>
        </template>
        <template #cell(packing_layer)="data">
          <div class="!min-w-[170px]">
            <div
              v-if="disabled"
              class="flex gap-2"
            >
              <b-form-group
                v-for="(item, index) in data.item.packing_material"
                :key="index"
                :label="item.label"
              >
                <b-input
                  v-model="data.item.packing_material[index].layer"
                  :formatter="formatPackingLayer"
                  :disabled="data.item.packing_material[index].isActive === false"
                  number
                />
              </b-form-group>
            </div>
            <div
              v-else
              style="white-space: pre-line;"
            >
              {{ handleMultiplePacking(data.item.packing_detail) }}
            </div>
          </div>
        </template>
        <template #cell(volume)="data">
          <ul>
            <li>P: {{ data.item.length }} cm</li>
            <li>L: {{ data.item.width }} cm</li>
            <li>T: {{ data.item.height }} cm</li>
          </ul>
        </template>
        <template #cell(income)="data">
          <span v-if="!disabled">
            {{ IDR(data.item.addon_cost) }} / {{ data.item.addon_minimum }} Pcs
          </span>
          <div
            v-else
            class="flex items-center"
          >
            <BFormInput
              v-model="data.item.addon_cost"
              type="number"
              style="width: 100px"
              @keypress="isNumber"
            />
            <span class="mx-1">/</span>
            <BInputGroup>
              <BFormInput
                v-model="data.item.addon_minimum"
                type="number"
                @keypress="isNumber"
              />
              <template #append>
                <BFormInput
                  placeholder="Pcs"
                  disabled
                  style="width: 60px"
                />
              </template>
            </BInputGroup>
          </div>
          <div v-if="data.item.is_variant !== 0">
            <BCollapse :id="`collapse-${String(data.index)}`" />
            <div
              v-if="data.item.variant.length > 1"
              v-b-toggle="`collapse-${String(data.index)}`"
              variant="flat-dark"
              size="sm"
              class="flex items-center"
            >
              <span
                class="when-opened"
                style="color: #08A0F7"
              >
                Sembunyikan Variasi
              </span>
              <span
                class="when-closed"
                style="color: #08A0F7"
              >
                Tampilkan Variasi Lainnya
              </span>
              <feather-icon
                icon="ChevronDownIcon"
                class="when-closed"
              />
              <feather-icon
                icon="ChevronUpIcon"
                class="when-opened"
              />
            </div>
          </div>
        </template>
        <template #cell(variant)="data">
          <div class="min-w-[150px]">
            <div v-if="data.item.is_variant !== 0">
              <div
                v-for="(item,idx) in data.item.variant.slice(0, 1)"
                :key="idx"
              >
                <span v-b-tooltip.hover.top="item.variant_name.length >= 10 ? item.variant_name : null">{{ truncateWithEllipsis(item.variant_name) }}</span>
              </div>
              <BCollapse :id="`collapse-${String(data.index)}`">
                <div
                  v-for="(item, idx) in data.item.variant.slice(1)"
                  :key="idx"
                  class="mb-50"
                >
                  <div class="my-1">
                    <span v-b-tooltip.hover.top="item.variant_name.length >= 10 ? item.variant_name : null">{{ truncateWithEllipsis(item.variant_name) }}</span>
                  </div>
                </div>
              </BCollapse>
            </div>
            <div v-else>
              Tidak ada variasi
            </div>
          </div>
        </template>
        <template #cell(stock)="data">
          <div v-if="data.item.is_variant !== 0">
            <div
              v-for="(item,idx) in data.item.variant.slice(0, 1)"
              :key="idx"
            >
              <span>{{ item.variant_stock }}</span>
            </div>
            <BCollapse :id="`collapse-${String(data.index)}`">
              <div
                v-for="(item, idx) in data.item.variant.slice(1)"
                :key="idx"
                class="mb-50"
              >
                <div class="my-1">
                  {{ item.variant_stock }}
                </div>
              </div>
            </BCollapse>
          </div>
          <div v-else>
            {{ data.item.stock }}
          </div>
        </template>
        <template #cell(sold)="data">
          <div v-if="data.item.is_variant !== 0">
            <div
              v-for="(item,idx) in data.item.variant.slice(0, 1)"
              :key="idx"
            >
              <div>
                {{ item.variant_sold }}
              </div>
            </div>
            <BCollapse :id="`collapse-${String(data.index)}`">
              <div
                v-for="(item, idx) in data.item.variant.slice(1)"
                :key="idx"
                class="mb-50"
              >
                <div
                  class="my-1"
                >
                  {{ item.variant_sold }}
                </div>
              </div>
            </BCollapse>
          </div>
          <div v-else>
            {{ data.item.sold }}
          </div>
        </template>
        <template #row-details="data">
          <b-row
            class="-mt-[1.3rem] pl-[3.1rem]"
          >
            <b-col cols="12">
              <div class="flex">
                <div
                  v-b-toggle="`bundling-${String(data.index)}`"
                  class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                    alt="bundling"
                    width="16"
                  >
                  <div>Bundling</div>
                </div>
              </div>
              <b-collapse
                :id="`bundling-${String(data.index)}`"
              >
                <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                  <div class="border rounded-t w-[70%]">
                    <div class="bg-[#FFECE9] py-[8px] px-[28px] rounded-t text-black font-[500]">
                      Produk
                    </div>
                    <b-table
                      :items="data.item.bundle_component"
                      :fields="fieldsBundling"
                    >
                      <template #cell(no)="data">
                        {{ data.index + 1 }}
                      </template>
                      <template #cell(product_name)="data">
                        <div class="flex gap-[16px] items-start">
                          <img
                            :src="data.item.product_image[0] ? data.item.product_image[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                            alt="product"
                            width="52"
                            height="52"
                          >
                          <div class="space-y-[4px]">
                            <div class="font-medium">
                              {{ data.item.product_name }}
                            </div>
                            <div class="text-[12px] text-primary">
                              SKU: {{ data.item.sku }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="flex ml-[0.8rem]">
                  <div
                    v-b-toggle="`bundling-${String(data.index)}`"
                    class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                  >
                    <feather-icon
                      icon="ChevronUpIcon"
                    />
                    <div>Tutup</div>
                  </div>
                </div>
              </b-collapse>
            </b-col>
          </b-row>
        </template>
      </BTable>
    </BOverlay>

    <template #footer>
      <div class="text-end">
        <BButton
          v-b-modal.modal-cancel
          variant="outline-primary"
          class="mr-1"
          :disabled="disabled ? false : true"
        >
          Batalkan
        </BButton>
        <BButton
          variant="primary"
          :disabled="checkDisabledButton() || disableBtn"
          @click="onFinish"
        >
          <B-Spinner
            v-if="loadingSubmit"
            small
            variant="light"
          />
          Simpan
        </BButton>
      </div>
    </template>

    <ModalCancel />
  </BCard>
</template>

<script>
import {
  VBModal,
} from 'bootstrap-vue'
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { axiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import isEmpty from 'lodash/isEmpty'
import { isNumber } from '@/libs/helpers'
import { fieldDetailProduct, fieldsBundling } from './config'
import ModalCancel from './ModalCancel.vue'

export default {
  components: { ModalCancel },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      IDR,
      isEmpty,
      loading: false,
      loadingSubmit: false,
      items: [],
      fields: fieldDetailProduct,
      dataFullfilmentProduct: secureLocalStorage.get('fullfilmentProduct'),
      dataFullfilment: secureLocalStorage.get('fullfilment'),
      disabled: false,
      isNumber,
      disableBtn: false,
      fieldsBundling,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const partnerId = this.dataFullfilmentProduct.id
      const warehouseId = this.dataFullfilment.id

      const url = `/v1/warehouse/fulfillment/product?partner_id=${partnerId}&warehouse_id=${warehouseId}`
      await axiosIns.get(url)
        .then(async res => {
          const { data } = res.data
          const response = await axiosIns.get('/kompack/select-option/packing')
          const packingOptions = response.data.data
          const updatedData = await Promise.all(data.map(async item => {
            const baseMaterials = [
              { label: 'PL', text: 'Plastik HD' },
              { label: 'KD', text: 'Kardus' },
              { label: 'BW', text: 'Bubblewrap' },
            ]

            const packingMaterial = baseMaterials.map(baseMaterial => {
              const apiMaterial = packingOptions.find(option => option.name === baseMaterial.text)
              return {
                ...baseMaterial,
                layer: 0,
                isActive: false,
                product_warehouse_id: item.packing_detail[0].product_warehouse_id,
                packing_detail_id: null,
                id_packing: apiMaterial ? apiMaterial.id : null,
              }
            })

            const updatedPackingMaterial = packingMaterial.map(material => {
              const matchingDetail = item.packing_detail.find(detail => detail.packing_material === material.text)

              if (matchingDetail) {
                return {
                  ...material,
                  layer: matchingDetail.packing_layer,
                  isActive: true,
                  product_warehouse_id: matchingDetail.product_warehouse_id,
                  packing_detail_id: matchingDetail.packing_detail_id,
                  id_packing: matchingDetail.id_packing || material.id_packing,
                }
              }

              return material
            })

            return {
              ...item,
              packing_detail: item.packing_detail.map(e => ({ ...e })),
              packing_material: updatedPackingMaterial,
              _showDetails: item.is_bundling === 1,
            }
          }))

          this.items = updatedData
          this.loading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.loading = false
        })
    },
    async onFinish() {
      this.loadingSubmit = true
      this.disableBtn = true
      const data = this.items.map(item => ({
        product_id: item.id,
        fulfillment_cost: Math.floor(item.fulfillment_cost),
        addon_cost: Math.floor(item.addon_cost),
        addon_minimum: Math.floor(item.addon_minimum),
        packing_details: item.packing_material.filter(e => e.isActive).map(e => ({
          packing_detail_id: e.packing_detail_id,
          product_warehouse_id: e.product_warehouse_id,
          id_packing: e.id_packing,
          packing_layer: e.layer,
        })),
      }))
      const payload = {
        partner_id: this.dataFullfilmentProduct.id,
        warehouse_id: this.dataFullfilment.id,
        product: data,
      }

      const url = '/v1/warehouse/fulfillment/product/update'
      await axiosIns.put(url, payload)
        .then(() => {
          this.loadingSubmit = false
          this.disabled = false
          this.disableBtn = true
          this.getData()
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Biaya fulfillment berhasil diedit',
              variant: 'success',
            },
          }, 2000)
        })
        .catch(err => {
          this.disableBtn = true
          this.loadingSubmit = false
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            }, { timeout: 2000 },
          )
        })
    },
    handlePhone(value) {
      if (value.startsWith('0')) {
        return window.open(`https://wa.me/62${value.substring(1)}`, '_blank')
      }
      return window.open(`https://wa.me/${value}`, '_blank')
    },
    onClickEdit(e) {
      this.disabled = true
      this.disableBtn = false
    },
    checkDisabledButton() {
      if (this.disabled) {
        return false
      }
      return true
    },
    truncateWithEllipsis(text) {
      return text.length <= 10 ? text : `${text.slice(0, 10)}...`
    },
    handlePackingText(data) {
      const selectedOptions = data.filter(option => option.isActive)
      const count = selectedOptions.length

      switch (count) {
        case 0:
          return 'Pilih bahan'
        case 1:
          return selectedOptions[0].text
        default:
          return `${count} Terpilih`
      }
    },
    packingOptions(value) {
      const { item } = value
      return item.packing_detail.filter(e => e.isActive === true)
    },
    formatPackingLayer(value) {
      const numericValue = value.replace(/[^0-9]/g, '')

      if (numericValue === '') {
        return '0'
      }

      if (parseInt(numericValue, 0) > 9) {
        return '9'
      }

      return numericValue
    },
    handleMultiplePacking(value) {
      return value.map(obj => `${obj.packing_material} : ${obj.packing_layer}`).join('\n')
    },
  },
}
</script>
<style scoped>
.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>
